<template>
    <transition-group :style="{zIndex: zIndex}" name="toast_trans" tag="div" class="toast_container"
                      @after-leave="afterLeave"
                      @after-enter="afterEnter">
        <div class="toast_item" :data-id="item.id"
             :style="{minWidth: item.minWidth, width: item.width}"
             v-for="item of items" :key="item.id" v-html="item.text">
        </div>
    </transition-group>
</template>

<script>
    export default {
        name: 'Toast',
        props: {
            zIndex: Number,
            newItem: {
                type: Object,
                default: null
            }
        },
        data () {
            return {
                items: [],
                id: 0
            }
        },
        watch: {
            newItem (newItem) {
                newItem && this.addToast(newItem)
            }
        },
        methods: {
            afterEnter (el) {
                const item = this.items.find(item => item.id === parseInt(el.dataset.id))
                item.width = `${el.getBoundingClientRect().width}px`
                if (this.del) return
                this.del = setTimeout(this.items[0].dismiss, this.items[0].duration)
            },
            afterLeave (el) {
                if (this.items.length) {
                    const next = this.items[0]
                    const existedDuration = Math.floor(Date.now()) - next.start_at
                    setTimeout(next.dismiss, Math.max(next.duration - existedDuration, 0))
                } else {
                    this.del = 0
                }
            },
            addToast ({
                          text = '', duration = 1500, width = '150px', onDismiss = null
                      }) {
                if (text === '') return

                const id = this.id++
                const startAt = Math.floor(Date.now())
                const dismiss = () => {
                    const index = this.items.findIndex(item => item.id === id)
                    if (index > -1) {
                        this.items[index].onDismiss && this.items[index].onDismiss()
                        this.items.splice(index, 1)
                    }
                }

                this.items.push({
                    text, duration, id, dismiss, start_at: startAt, width: '', minWidth: width, onDismiss
                })
            }
        }
    }
</script>

<style scoped lang="less">
    @import "../less/_variables";
    @import "../less/_mixin";

    .toast_container {
        position: fixed;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 9998;
    }

    .toast_item {
        background-color: rgba(0, 0, 0, .9);
        color: #fff;
        font-size: 14px;
        line-height: 1.5;
        padding: 10px;
        text-align: center;
        width: fit-content;
        border-radius: 4px;
        margin-bottom: 10px;
    }

    .toast_trans-enter-active, .toast_trans-leave-active, .toast_trans-move {
        transition-property: transform, opacity;
        transition-duration: .4s;
    }

    /*
        use `position: absolute` of `v-leave-active` state
        so that layout of left elements will be affected
        and `v-move` will make effects.
    */
    .toast_trans-leave-active {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
    }

    .toast_trans-enter {
        opacity: 0;
        transform: translateY(100%);
    }

    .toast_trans-leave-to {
        opacity: 0;
        transform: translateX(-50%) translateY(-100%);
    }

</style>
