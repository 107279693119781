import Vue from 'vue'
import Router from 'vue-router'
import Util from '@/utils/util'
import VueLazyload from 'vue-lazyload'
import LoadingView from '@/components/Loading'

const originalPush = Router.prototype.push
Router.prototype.push = function push (location) {
    return originalPush.call(this, location).catch(err => {
        if (err === undefined) return
        return Promise.reject(err)
    })
}

Util.noop()
Vue.use(Router)
Vue.use(VueLazyload, {
    preLoad: 1.3,
    attempt: 1
})

const scrollBehavior = (to, from, savedPosition) => {
    return new Promise((resolve) => {
        if (savedPosition) {
            resolve(savedPosition)
        } else {
            resolve({ x: 0, y: 0 })
        }
    })
}

const loadingVm = new Vue(LoadingView)
loadingVm.$mount('#route_loader')
loadingVm.show = true
const AsyncPageComponent = (view) => {
    return resolve => {
        loadingVm.show = true
        import('@/pages/' + view).then((view) => {
            loadingVm.show = false
            resolve(view)
        })
    }
}

const router = new Router({
    mode: 'history',
    scrollBehavior,
    routes: [
        { path: '*', redirect: '/site/404' },
        {
            path: '/site/404',
            name: '404',
            components: {
                four_o_four: AsyncPageComponent(('site/NotFound'))
            },
            meta: {
                title: '抱歉，没有找到要访问的内容'
            }
        },
        {
            path: '/',
            name: 'index',
            components: {
                main: AsyncPageComponent(('Index'))
            },
            meta: {
                mainPage: true, footer: 'index', transition: '', index: 1, single: true
            }
        },
        {
            path: '/series/list',
            name: 'series',
            components: {
                main: AsyncPageComponent(('SeriesIndex'))
            },
            meta: {
                title: '系列课', mainPage: true, footer: 'series', transition: '', index: 2, single: true
            }
        },
        {
            path: '/feed/index',
            name: 'feed',
            components: {
                main: AsyncPageComponent(('FeedIndex'))
            },
            meta: {
                title: '时刻', mainPage: true, footer: 'feed', transition: '', index: 4, single: true
            }
        },
        {
            path: '/my/index',
            name: 'my',
            components: {
                main: AsyncPageComponent(('MyIndex'))
            },
            meta: {
                title: '个人中心', mainPage: true, footer: 'my', transition: '', index: 5, single: true
            }
        },
        {
            path: '/video/filter',
            name: 'video_filter',
            component: AsyncPageComponent(('video/Filter')),
            meta: {
                title: '课程筛选', mainPage: false
            }
        },
        {
            path: '/video/search',
            name: 'video_search',
            component: AsyncPageComponent(('video/Search')),
            meta: {
                title: '课程搜索', mainPage: false
            }
        },
        {
            path: '/video/index',
            name: 'video_index',
            component: AsyncPageComponent(('video/Index')),
            meta: {
                title: '全部课程', mainPage: false
            }
        },
        {
            path: '/subscription/index',
            name: 'subscription_index',
            component: AsyncPageComponent(('subscription/Index')),
            meta: {
                title: '我的订阅', mainPage: false
            }
        },
        {
            path: '/free/index',
            name: 'free_index',
            component: AsyncPageComponent(('free/Index')),
            meta: {
                title: '限时免费', mainPage: false
            }
        },
        {
            path: '/just-for-you',
            name: 'just_for_you',
            component: AsyncPageComponent(('just-for/You')),
            meta: {
                title: '', mainPage: false
            }
        },
        {
            path: '/wechat/bind',
            name: 'wechat_bind',
            component: AsyncPageComponent(('wechat/Bind')),
            meta: {
                title: '绑定公众号', mainPage: false
            }
        },
        {
            path: '/video/detail',
            name: 'video_detail',
            component: AsyncPageComponent(('video/Detail')),
            meta: {
                title: '课程详情', mainPage: false
            }
        },
        {
            path: '/series/detail',
            name: 'series_detail',
            component: AsyncPageComponent(('series/Detail')),
            meta: {
                title: '系列课详情', mainPage: false
            }
        },
        {
            path: '/series/index',
            name: 'series_list',
            component: AsyncPageComponent(('series/List')),
            meta: {
                title: '全部系列课', mainPage: false
            }
        },
        {
            path: '/series/rank',
            name: 'series_rank',
            component: AsyncPageComponent(('series/Rank')),
            meta: {
                title: '排行榜', mainPage: false
            }
        },
        {
            path: '/series/video',
            name: 'series_video',
            component: AsyncPageComponent(('series/Video')),
            meta: {
                title: '系列课详情', mainPage: false
            }
        },
        {
            path: '/teacher/detail',
            name: 'teacher_detail',
            component: AsyncPageComponent(('teacher/Detail')),
            meta: {
                title: '老师主页', mainPage: false
            }
        },
        {
            path: '/td',
            redirect: '/teacher/detail'
        },
        {
            path: '/user/home',
            name: 'user_home',
            component: AsyncPageComponent(('user/Home')),
            meta: {
                title: '个人主页', mainPage: false
            }
        },
        {
            path: '/vuser/home',
            name: 'vuser_home',
            component: AsyncPageComponent(('vuser/Home')),
            meta: {
                title: '大使主页', mainPage: false
            }
        },
        {
            path: '/vuser/amount-detail',
            name: 'vuser_amount_detail',
            component: AsyncPageComponent(('vuser/AmountList')),
            meta: {
                title: '入账明细', mainPage: false
            }
        },
        {
            path: '/about/level',
            name: 'about_level',
            component: AsyncPageComponent(('about/Level')),
            meta: {
                title: '课程难度说明', mainPage: false, detect: false
            }
        },
        {
            path: '/about/category',
            name: 'about_category',
            component: AsyncPageComponent(('about/Category')),
            meta: {
                title: '课程类型说明', mainPage: false, detect: false
            }
        },
        {
            path: '/about/series',
            name: 'about_series',
            component: AsyncPageComponent(('about/Series')),
            meta: {
                title: '系列课说明', mainPage: false, detect: false
            }
        },
        {
            path: '/about/coupon',
            name: 'about_coupon',
            component: AsyncPageComponent(('about/Coupon')),
            meta: {
                title: '优惠券说明', mainPage: false, detect: false
            }
        },
        {
            path: '/about/index',
            name: 'about_index',
            component: AsyncPageComponent(('about/Index')),
            meta: {
                title: '关于全是瑜', mainPage: false, detect: false
            }
        },
        {
            path: '/protocol/index',
            name: 'protocol_index',
            component: AsyncPageComponent(('protocol/Index')),
            meta: {
                title: '全是瑜 - 用户协议', mainPage: false, detect: false
            }
        },
        {
            path: '/protocol/detail',
            name: 'protocol_detail',
            component: AsyncPageComponent(('protocol/Detail')),
            meta: {
                title: '全是瑜', mainPage: false, detect: false
            }
        },
        {
            path: '/series/present',
            name: 'series_present',
            component: AsyncPageComponent(('series/Present')),
            meta: {
                title: '系列课赠好友', mainPage: false
            }
        },
        {
            path: '/series/training',
            name: 'series_training',
            component: AsyncPageComponent(('series/Training')),
            meta: {
                title: '全是瑜教培系列课', mainPage: false
            }
        },
        {
            path: '/series/workshop',
            name: 'series_workshop',
            component: AsyncPageComponent(('series/Workshop')),
            meta: {
                title: '全是瑜工作坊系列课', mainPage: false
            }
        },
        {
            path: '/user/feedback',
            name: 'user_feedback',
            component: AsyncPageComponent(('user/Feedback')),
            meta: {
                title: '建议或问题反馈', mainPage: false, needLogin: true
            }
        },
        {
            path: '/user/account',
            name: 'user_account',
            component: AsyncPageComponent(('user/Account')),
            meta: {
                title: '账户与安全', mainPage: false, needLogin: true
            }
        },
        {
            path: '/user/edit-account',
            name: 'user_edit_account',
            component: AsyncPageComponent(('user/EditAccount')),
            meta: {
                title: '编辑资料', mainPage: false, needLogin: true
            }
        },
        {
            path: '/user/collect',
            name: 'user_collect',
            component: AsyncPageComponent(('user/Collect')),
            meta: {
                title: '我的收藏', mainPage: false, needLogin: true
            }
        },
        {
            path: '/user/topic-collect',
            name: 'user_topic_collect',
            component: AsyncPageComponent(('user/TopicCollect')),
            meta: {
                title: '我的收藏', mainPage: false, needLogin: true
            }
        },
        {
            path: '/user/latest_practise',
            name: 'user_latest_practise',
            component: AsyncPageComponent(('user/LatestPractise')),
            meta: {
                title: '最近练习', mainPage: false, needLogin: true
            }
        },
        {
            path: '/user/bought',
            name: 'user_bought',
            component: AsyncPageComponent(('user/Bought')),
            meta: {
                title: '已购课程', mainPage: false, needLogin: true
            }
        },
        {
            path: '/user/setting',
            name: 'user_setting',
            component: AsyncPageComponent(('user/Setting')),
            meta: {
                title: '设置', mainPage: false, needLogin: true
            }
        },
        {
            path: '/user/edit_mobile',
            name: 'user_edit_mobile',
            component: AsyncPageComponent(('user/EditMobile')),
            meta: {
                title: '更换手机号', mainPage: false, needLogin: true
            }
        },
        {
            path: '/user/edit_nickname',
            name: 'user_edit_nickname',
            component: AsyncPageComponent(('user/EditNickname')),
            meta: {
                title: '修改昵称', mainPage: false, needLogin: true
            }
        },
        {
            path: '/user/vip-log',
            name: 'user_vip_log',
            component: AsyncPageComponent(('user/VipLog')),
            meta: {
                title: '明细记录', mainPage: false, needLogin: true
            }
        },
        {
            path: '/user/notice',
            name: 'user_notice',
            component: AsyncPageComponent(('user/Notice')),
            meta: {
                title: '系统消息', mainPage: false, needLogin: true
            }
        },
        {
            path: '/pay/vip',
            name: 'pay_vip',
            component: AsyncPageComponent(('pay/VipNew')),
            meta: {
                title: '购买会员', mainPage: false, needLogin: true
            }
        },
        {
            path: '/pay/mooc-order',
            name: 'pay_mooc_order',
            component: AsyncPageComponent(('pay/MoocOrder')),
            meta: {
                title: '慕课购买', mainPage: false, needLogin: false
            }
        },
        {
            path: '/pay/vip-new',
            name: 'pay_vip-new',
            component: AsyncPageComponent(('pay/VipNew')),
            meta: {
                title: '我的会员期', mainPage: false, needLogin: true
            }
        },
        {
            path: '/pay/series',
            name: 'pay_series',
            component: AsyncPageComponent(('pay/Series')),
            meta: {
                title: '购买系列课程', mainPage: false, needLogin: true
            }
        },
        {
            path: '/pay/series_group_buy',
            name: 'pay_series_group_buy',
            component: AsyncPageComponent(('pay/SeriesGroupBuy')),
            meta: {
                title: '拼团购订单', mainPage: false, needLogin: true
            }
        },
        {
            path: '/pay/series_coupon',
            name: 'pay_series_coupon',
            component: AsyncPageComponent(('pay/SeriesCoupon')),
            meta: {
                title: '可用抵价券', mainPage: false, needLogin: true
            }
        },
        {
            path: '/my/group_buy',
            name: 'my_group_buy',
            component: AsyncPageComponent(('my/GroupBuy')),
            meta: {
                title: '我的拼团', mainPage: false, needLogin: true
            }
        },
        {
            path: '/series/group_buy',
            name: 'series_group_buy',
            component: AsyncPageComponent(('series/GroupBuy')),
            meta: {
                title: '系列课拼团', mainPage: false
            }
        },
        {
            path: '/video/play',
            name: 'video_play',
            component: AsyncPageComponent(('video/Play')),
            props: true,
            meta: {
                title: false, mainPage: false, needLogin: true
            }
        },
        {
            path: '/user/coupon',
            name: 'user_coupon',
            component: AsyncPageComponent(('user/Coupon')),
            props: true,
            meta: {
                title: '我的优惠券', mainPage: false, needLogin: true
            }
        },
        {
            path: '/cooperation/wendao',
            name: 'cooperation_wendao',
            component: AsyncPageComponent(('cooperation/wendao')),
            props: true,
            meta: {
                title: '全是瑜', mainPage: false, detect: false
            }
        },
        {
            path: '/app/download_android',
            name: 'download_android',
            component: AsyncPageComponent(('app/DownloadAndroid')),
            props: true,
            meta: {
                title: '全是瑜', mainPage: false, detect: false
            }
        },
        {
            path: '/helper/api',
            name: 'helper_api',
            component: AsyncPageComponent(('helper/Api')),
            props: true,
            meta: {
                title: '全是瑜', mainPage: false, detect: false
            }
        },
        {
            path: '/site/invite',
            name: 'site_invite',
            component: AsyncPageComponent(('site/Invite')),
            props: true,
            meta: {
                title: '全是瑜', mainPage: false, detect: false
            }
        },
        {
            path: '/site/join',
            name: 'site_join',
            component: AsyncPageComponent(('site/Join')),
            props: true,
            meta: {
                title: '全是瑜', mainPage: false, detect: false
            }
        },
        {
            path: '/si',
            redirect: '/site/invite'
        },
        {
            path: '/site/native',
            name: 'site_native',
            component: AsyncPageComponent(('site/Native')),
            props: true,
            meta: {
                title: 'native', mainPage: false, detect: false
            }
        },
        {
            path: '/site/star',
            name: 'site_star',
            component: AsyncPageComponent(('site/Star')),
            props: true,
            meta: {
                title: 'star', mainPage: false, detect: false
            }
        },
        {
            path: '/pay/get_openid',
            name: 'page_get_openid',
            component: AsyncPageComponent(('pay/Openid')),
            meta: {
                title: '微信核验', mainPage: false
            }
        },
        {
            path: '/helper/index',
            name: 'helper_index',
            component: AsyncPageComponent(('helper/Index')),
            meta: {
                title: '全是瑜测试工具', mainPage: false
            }
        },
        {
            path: '/helper/wechat-card',
            name: 'helper_wechat_card',
            component: AsyncPageComponent(('helper/WechatCard')),
            meta: {
                title: '测试添加卡券', mainPage: false
            }
        },
        {
            path: '/helper/practise_monitor',
            name: 'practise_monitor',
            component: AsyncPageComponent(('helper/PractiseMonitor')),
            meta: {
                title: '全是瑜测试工具', mainPage: false
            }
        },
        {
            path: '/teacher/list',
            name: 'teacher_list',
            component: AsyncPageComponent(('teacher/List')),
            meta: {
                title: '全是瑜 - 核心老师', mainPage: false
            }
        },
        {
            path: '/teacher/index',
            name: 'teacher_index',
            component: AsyncPageComponent(('teacher/Index')),
            meta: {
                title: '全是瑜 - 合作老师', mainPage: false
            }
        },
        {
            path: '/live/index',
            name: 'live_index',
            components: {
                main: AsyncPageComponent(('live/Index'))
            },
            meta: {
                title: '全是瑜·云小课', mainPage: true, footer: 'index', transition: '', index: 1, single: true, component: 'live'
            }
        },
        {
            path: '/live/my',
            name: 'live_my',
            components: {
                main: AsyncPageComponent(('live/My'))
            },
            meta: {
                title: '我的课程', mainPage: true, footer: 'my', transition: '', index: 2, single: true, component: 'live'
            }
        },
        {
            path: '/live/user-course',
            name: 'live_user_course',
            component: AsyncPageComponent(('live/UserCourse')),
            meta: {
                title: '我的课程', mainPage: false, needLogin: true, detect: false
            }
        },
        {
            path: '/pay/live-course',
            name: 'live_course_detail',
            component: AsyncPageComponent(('pay/LiveCourse')),
            meta: {
                title: '全是瑜·云小课', mainPage: false
            }
        },
        {
            path: '/live/msg-success',
            name: 'live_course_msg_success',
            component: AsyncPageComponent(('live/MsgSuccess')),
            meta: {
                title: '购买成功', mainPage: false
            }
        },
        {
            path: '/topic/list',
            name: 'topic_list',
            component: AsyncPageComponent(('topic/List')),
            meta: {
                title: '专题练，省心练', mainPage: false
            }
        },
        {
            path: '/topic/detail',
            name: 'topic_detail',
            component: AsyncPageComponent(('topic/Detail')),
            meta: {
                title: '全是瑜', mainPage: false, detect: false
            }
        },
        {
            path: '/collection/list',
            name: 'collection_list',
            component: AsyncPageComponent(('collection/List')),
            meta: {
                title: '编选课集', mainPage: false
            }
        },
        {
            path: '/collection/detail',
            name: 'collection_detail',
            component: AsyncPageComponent(('collection/Detail')),
            meta: {
                title: '全是瑜', mainPage: false
            }
        },
        {
            path: '/course-topic/detail',
            name: 'course_topic_detail',
            component: AsyncPageComponent(('course-topic/Detail')),
            meta: {
                title: '全是瑜', mainPage: false
            }
        },
        {
            path: '/yogagym/list',
            name: 'yogagym_list',
            component: AsyncPageComponent(('yogagym/List')),
            meta: {
                title: '合作瑜伽馆', mainPage: false
            }
        },
        {
            path: '/teacher/guest_teacher',
            name: 'teacher_guest_list',
            component: AsyncPageComponent(('teacher/GuestList')),
            meta: {
                title: '全是瑜 - 客座老师', mainPage: false
            }
        },
        {
            path: '/tag/detail',
            name: 'tag_detail',
            component: AsyncPageComponent(('tag/Detail')),
            meta: {
                title: '全是瑜 - 课程标签', mainPage: false
            }
        },
        {
            path: '/article/detail',
            name: 'article_detail',
            component: AsyncPageComponent(('article/Detail')),
            meta: {
                title: '全是瑜', mainPage: false
            }
        },
        {
            path: '/article/long-detail',
            name: 'article_long_detail',
            component: AsyncPageComponent(('article/LongDetail')),
            meta: {
                title: '全是瑜', mainPage: false
            }
        },
        {
            path: '/yogagym/detail',
            name: 'yogagym_detail',
            component: AsyncPageComponent(('yogagym/Detail')),
            meta: {
                title: '全是瑜 - 合作瑜伽馆', mainPage: false
            }
        },
        {
            path: '/yd',
            redirect: '/yogagym/detail'
        },
        {
            path: '/feed/video',
            name: 'feed_video',
            component: AsyncPageComponent(('feed/Video')),
            meta: {
                title: '全是瑜', mainPage: false
            }
        },
        {
            path: '/feed/detail',
            name: 'feed_detail',
            component: AsyncPageComponent(('feed/Detail')),
            meta: {
                title: '时刻详情', mainPage: false, detect: false
            }
        },
        {
            path: '/fd',
            redirect: '/feed/detail'
        },
        {
            path: '/comment/detail',
            name: 'comment_detail',
            component: AsyncPageComponent(('comment/Detail')),
            meta: {
                title: '查看回复', mainPage: false, detect: false
            }
        },
        {
            path: '/faq/recharge',
            name: 'faq_recharge',
            component: AsyncPageComponent(('faq/Recharge')),
            meta: {
                title: '充值常见问题', mainPage: false, detect: false
            }
        },
        {
            path: '/faq/recharge-special',
            name: 'faq_recharge_special',
            component: AsyncPageComponent(('faq/RechargeSpecial')),
            meta: {
                title: '充值特殊问题', mainPage: false, detect: false
            }
        },
        {
            path: '/faq/vip-buy',
            name: 'faq_vip_buy',
            component: AsyncPageComponent(('faq/VipBuy')),
            meta: {
                title: '会员期购买常见问题', mainPage: false, detect: false
            }
        },
        {
            path: '/faq/vip-buy-special',
            name: 'faq_vip_buy_special',
            component: AsyncPageComponent(('faq/VipBuySpecial')),
            meta: {
                title: '特殊问题', mainPage: false, detect: false
            }
        },
        {
            path: '/faq/series-buy',
            name: 'faq_series_buy',
            component: AsyncPageComponent(('faq/SeriesBuy')),
            meta: {
                title: '购买常见问题', mainPage: false, detect: false
            }
        },
        {
            path: '/site/unregister',
            name: 'unregister',
            component: AsyncPageComponent(('site/Unregister')),
            meta: {
                title: '注销账号',
                mainPage: false,
                detect: false,
                needLogin: true
            }
        },
        {
            path: '/auth-redirect/:openid',
            name: 'auth_redirect',
            component: AsyncPageComponent(('site/AuthRedirect')),
            meta: {
                title: '登录中',
                mainPage: false,
                detect: false,
                needLogin: false
            }
        }
    ]
})
router.afterEach((to) => {
    if (to.name !== 'auth_redirect') {
        const { name, path, query, params, hash } = to
        window.sessionStorage.setItem('lastRoute', JSON.stringify({ name, path, query, params, hash }))
    }
    if (to.meta.title !== false) {
        document.title = to.meta.title || '全是瑜'
    }
})
export default router
