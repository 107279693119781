<template>
    <transition name="load_error_trans">
        <div class="load_error_layer" v-show="show" @click.prevent="reload">
            文件加载失败，请<a href="#">刷新页面</a>
        </div>
    </transition>
</template>

<script>
    export default {
        name: 'ChunkLoadError',
        props: {
            show: {
                type: Boolean,
                default: false
            }
        },
        data () {
            return {}
        },
        methods: {
            reload () {
                this.$emit('model', false)
                location.reload()
            }
        }
    }
</script>

<style scoped lang="less">
    @import "../less/_variables";
    @import "../less/_mixin";

    .load_error_layer {
        white-space: nowrap;
        height: 50px;
        bottom: 80px;
        position: fixed;
        z-index: 9999;
        border-radius: 6px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(0, 0, 0, 1);
        color: @white;
        padding: 0 15px;
        transform: translateX(-50%);
        left: 50%;

        a {
            color: @colorPrimary;
        }
    }

    .load_error_trans-enter-active, .load_error_trans-leave-active {
        transition: all .25s;
        transition-timing-function: ease-in;
    }

    .load_error_trans-enter, .load_error_trans-leave-to {
        opacity: 0;
        transform: translateX(-50%) scale(.5);
    }
</style>
