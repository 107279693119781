<template>
    <div id="app">
        <router-view name="four_o_four"/>
        <div class="app_body">
            <div key="main" v-show="$route.meta.mainPage" class="page_wrapper main">
                <live-footer-bar ref="footer" :current="$route.meta.footer" v-if="$route.meta.component === 'live'"/>
                <footer-bar ref="footer" :current="$route.meta.footer" v-else/>
                <keep-alive>
                    <router-view name="main"/>
                </keep-alive>
            </div>
            <div key="other" v-show="!$route.meta.mainPage" class="page_wrapper">
                <navigation>
                    <router-view/>
                </navigation>
            </div>
        </div>
        <div class="new_activity" v-if="showNewActivity">
            <div class="mask"></div>
            <div class="activity_area">
                <a :href="new_activity.link" class="activity_link">
                    <img :src="new_activity.image" alt="">
                </a>
                <a href="javascript:" class="activity_close" @click.prevent="new_activity_show = 0"> <i
                    class="iconfont icon-close2"></i> </a>
            </div>
        </div>
    </div>
</template>

<script>
    import FooterBar from '@/components/FooterBar'
    import LiveFooterBar from '@/components/LiveFooterBar'
    import Service from '@/service'

    export default {
        name: 'App',
        data () {
            return {
                new_activity: null,
                new_activity_show: 1
            }
        },
        created () {
            if (this.$app.isNative) {
                const shareData = this.getDefShareData()
                this.$app.native.setNativeShareInfo({
                    share_title: shareData.title,
                    share_desc: shareData.desc,
                    share_img: shareData.imgUrl,
                    share_link: shareData.link
                }, {})
            }
        },
        computed: {
            showNewActivity () {
                return this.new_activity != null && this.new_activity_show > 0
            }
        },
        components: {
            FooterBar,
            LiveFooterBar
        },
        methods: {
            checkNewActivity () {
                if (this.$app.isNative) return
                if (this._checkedNewActivity) return
                const saveNewestActivityId = function (id) {
                    localStorage.setItem('newest_activity_id', id)
                }
                const getNewestActivityId = function () {
                    return localStorage.getItem('newest_activity_id') || '0'
                }

                const oldActivityId = parseInt(getNewestActivityId())
                this.$app.ajax.get(Service.site_newest_activity).then(res => {
                    if (res.code === 200 && res.data && oldActivityId !== res.data.id) {
                        if (res.data.link.indexOf(location.pathname) > -1) return
                        const image = new Image()
                        image.onload = () => {
                            this.new_activity = res.data
                            saveNewestActivityId(res.data.id)
                        }
                        image.src = res.data.image
                    }
                })
                this._checkedNewActivity = true
            }
        }
    }
</script>

<style lang="less">
    @import "./less/zui/zui";
    @import "./less/mod/common";
    @import "./less/mod/form";

    @trans-duration: .4s;

    body {
        min-height: 100%;
        display: flex;
        flex-direction: column;
    }

    #app, .app_body, .page_wrapper {
        flex: 1;
        display: flex;
        flex-direction: column;
    }

    .page {
        flex: 1;
    }

    #app, .app_body, .page_wrapper {
        position: relative;
    }

    .page_wrapper {
        flex: 1;
        background-color: @white;
        position: relative;

        &.main {
            .footer-bar.trans {
                position: absolute;
            }
        }
    }

    .no_trans-leave-active {
        transition-property: opacity;
        transition-duration: @trans-duration;
    }

    .no_trans-leave-to {
        opacity: 0.99;
    }

    .trans_left-enter-active, .trans_left-leave-active,
    .trans_right-enter-active, .trans_right-leave-active {
        transition-property: transform, opacity;
        transition-duration: @trans-duration;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        min-height: 100%;
    }

    .trans_left-enter {
        opacity: 0;
        transform: translateX(100%);
    }

    .trans_left-leave-to {
        opacity: 0;
        transform: translateX(-100%);
    }

    .trans_right-enter {
        opacity: 0;
        transform: translateX(-100%);
    }

    .trans_right-leave-to {
        opacity: 0;
        transform: translateX(100%);
    }

    .new_activity {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        min-height: 100%;
        z-index: 200;

        .mask {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            min-height: 100%;
            background-color: rgba(0, 0, 0, .5);
        }

        .activity_area {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            .activity_link {
                display: block;
                width: 250px;

                img {
                    width: 100%;
                    display: block;
                    border-radius: 4px;
                }
            }
        }

        .activity_close {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            margin-top: 15px;

            .iconfont {
                color: @gray;
                background-color: @white;
                border-radius: 50%;
                padding: 6px;
            }
        }
    }
</style>
