export default {
    error_report: 'web-error-report/create',
    app_auth_token_get: 'sass/site/app-auth-token/get',
    mini_token_get: 'mini/access-token/get',
    mini_token_clear_post: 'mini/access-token-clear/post',
    token_get: 'sass/site/token/get',
    req_payload_set: 'site/req-payload/post',
    site_state: 'site/state-v2/get',
    site_index: 'site/index-v3/get',
    subscription_index: 'site/subscription-index/get',
    free_index: 'site/free-index/get',
    just_for_you: 'site/new-index/get',
    series_index: 'series/index/get',
    series_rank: 'series/rank/get',
    site_search_index: 'site/search-index/get',
    video_index: 'video/index-v2/get',
    login: 'users/login/post',
    video_page_list: 'video/page-list',
    sub_video_list: 'video/sub-videos/get',
    video_bought_page_list: 'video/page-list-v2/get?action=bought',
    video_latest_page_list: 'video/page-list-v2/get?action=view',
    video_collect_page_list: 'video/page-list-v2/get?action=collect',
    video_follow_page_list: 'video/page-list-v2/get?action=follow',
    video_site_follow_page_list: 'video/page-list-v2/get?action=site-follow',
    common_banner_page_list: 'common-banner/page-list',
    video_filter: 'video-filter/all/get',
    level_desc: 'video-filter/level-desc/get',
    category_desc: 'video-filter/category-desc/get',
    series_type: 'video-filter/series-type/get',
    series_filter: 'series/filter/get',
    series_page_list: 'series/page-list',
    initial_present: 'series/initial-present/post',
    users_login_user: 'users/login-user/get',
    service_protocol_list: 'service-protocol/list/get',
    service_protocol_detail: 'service-protocol/query/',
    feedback_create: 'feedback/create',
    site_img_captcha: 'site/img-captcha/get',
    site_sms_captcha: 'site/sms-captcha/post',
    site_simple_sms_captcha: 'site/simple-sms-captcha/post',
    page_view_stats: 'site/page-view-stats/post',
    view_stats_create: 'view-stats/create',
    user_set_openid: 'users/openid-set/post',
    user_log_out: 'users/logout/post',
    user_register: 'users/regist/post',
    user_unregister: 'users/unregister/post',
    user_unregister_cancel: 'users/unregister-cancel/post',
    user_unregister_query: 'users/unregister/get',
    user_sms_login: 'users/sms-login/post',
    user_findpwd: 'users/findpwd/post',
    user_register_check: 'users/regist-check/get',
    user_register_coupon_check: 'users/coupon-check/post',
    user_home_info_get: 'users/home-info/get',
    activate_free_vip: 'users/regist-free/post',
    weixin_auth_url: 'weixin/auth-url/get',
    weixin_login: 'users/login-weixin/post',
    edit_user_info: 'users/edit-info/post',
    cancel_bind_weixin: 'weixin/cancel-bind/post',
    bind_weixin: 'weixin/bind/post',
    weixin_openid: 'weixin/openid/get',
    switch_plan_line: 'users/switch-line/post',
    message_page_list: 'message/page-list-v3/get',
    message_read_all: 'message/read-all/post',
    message_has_new: 'message/has-new-v2/get',
    vip_log_page_list: 'vip-log/page-list',
    vip_price_page_list: 'vip-price/page-list',
    vip_order: 'vip/create-order-v2/post',
    site_qrcode: 'site/qrcode/get',
    my_group_buy: 'series/group-buy-list/get',
    video_favorite: 'video-favorite/create',
    play_track: 'video/play-track-h5/get',
    video_detail: 'video/query/',
    series_detail: 'series/query/',
    teacher_detail: 'teacher/query-v2/get',
    tag_detail: 'tag/query/',
    series_training_index: 'series/training-index/get',
    series_workshop_index: 'series/workshop-index/get',
    series_group_buy: 'series/group-buy/get',
    join_series_group_buy: 'series/join-group-buy/post',
    series_group_buy_detail: 'series/group-buy-detail/get',
    series_group_buy_check: 'series/join-group-buy-check/get',
    series_present_detail: 'series/present-detail/get',
    series_present_coupon: 'series/present-coupon/post',
    user_coupon_list: 'coupon/page-list',
    vip_coupon_exchange: 'vip/exchange/post',
    coupon_video_buy_check: 'coupon/video-buy-check/get',
    coupon_exchange: 'coupon/exchange/post',
    activity_custom_post: 'activity/custom/post',
    activity_custom_get: 'activity/custom/post',
    invite_coupon_query: 'invite-coupon/invite-user/get',
    invite_coupon_create: 'invite-coupon/create',
    helper_invite: 'helper-invite/create',
    buy_detail: 'video/buy-detail-v2/get',
    create_video_order: 'video/create-order/post',
    video_available_coupon: 'coupon/video-available/get',
    coupon_series_exchange: 'coupon/series-exchange/post',
    weixin_jssdk_sign: 'weixin/jssdk-sign/get',
    share_report: 'share/report/post',
    feed_index_base: 'feed/index-base/get',
    feed_index_content: 'feed/index-content/get',
    feed_newest_list: 'feed/newest-list/get',
    feed_hottest_list: 'feed/hottest-list/get',
    feed_follow_list: 'feed/follow-list/get',
    feed_bubble_list: 'feed/bubble-list/get',
    feed_like: 'feed/like/post',
    feed_comment_create: 'feed-comment/create',
    feed_comment_page_list: 'feed-comment/page-list',
    feed_comment_like: 'feed-comment/like/post',
    feed_reply_comment_page_list: 'feed-comment/reply-page-list/get',
    feed_comment_query: 'feed-comment/query/',
    feed_comment_delete: 'feed-comment/delete/post',
    follow_teacher: 'teacher/follow/post',
    unfollow_teacher: 'teacher/cancel-follow/post',
    follow_brand: 'yoga-brand/follow/post',
    unfollow_brand: 'yoga-brand/cancel-follow/post',
    follow_tag: 'tag/follow/post',
    unfollow_tag: 'tag/cancel-follow/post',
    follow_user: 'users/follow/post',
    unfollow_user: 'users/cancel-follow/post',
    teacher_list: 'teacher/page-list',
    topic_list: 'topic/page-list',
    topic_collect_list: 'topic/collect-list/get',
    topic_detail: 'topic/query/',
    topic_collect_post: 'topic/collect/post',
    topic_click_post: 'topic/click/post',
    collection_index: 'collection/index/get',
    collection_list: 'collection/page-list',
    collection_detail: 'collection/query/',
    course_topic_detail: 'course-topic/query/',
    teacher_total: '/teacher/total/get',
    feed_owner_home_list: 'feed/owner-home-list/get',
    teacher_video_list: 'video/teacher-list/get',
    tag_video_list: 'video/tag-list/get',
    brand_video_list: 'video/brand-list/get',
    feed_owner_media_list: 'feed/owner-media-list/get',
    site_newest_activity: 'site/newest-activity/get',
    yogagym_list: 'yoga-brand/page-list',
    yogagym_detail: 'yoga-brand/query/',
    yogagym_sub_list: 'yoga-brand/sub-page-list/get',
    article_detail: 'article-base/query/',
    feed_query: 'feed/query/',
    feed_publish_page_list: 'feed/publish-page-list/get',
    feed_publish_admin_page_list: 'feed/publish-admin-page-list/get',
    feed_partner_get: 'feed/partner-feed/get',
    feed_partner_publish: 'feed/partner-publish/post',
    feed_partner_video_publish: 'feed/partner-video-publish/post',
    feed_partner_delete: 'feed/partner-delete/post',
    feed_partner_publish_index: 'feed/partner-publish-index/get',
    feed_partner_index: 'feed/partner-index/get',
    feed_publish_admin_index: 'feed/publish-admin-index/get',
    feed_admin_delete: 'feed/admin-delete/post',
    feed_admin_audit: 'feed/admin-audit/post',
    feed_admin_get: 'feed/admin-feed/get',
    practise_stats_get: 'practise-stats/line-graph-data/get',
    mooc_order_query_get: 'mooc/mooc-order/query-info/get',
    mooc_order_pay_post: 'mooc/mooc-order/pay/post',
    wechat_bind_post: 'users/wechat-bind/post',
    live_index_get: 'live/course/index/get',
    live_course_page_list: 'live/course/page-list',
    live_course_my_list: 'live/course/my-list/get',
    live_course_detail: 'live/course/query/',
    live_user_course_detail: 'live/user-course/query/',
    live_course_order_post: 'live/course/create-order/post',
    live_course_cancel_check: 'live/user-course/check-cancel/get',
    live_course_cancel: 'live/user-course/cancel/post',
    live_course_save_contact: 'live/user-course/save-contact/post',
    api_v2_vuser_coupon_code_validation: 'api_v2/vuser/coupon-code/validation',
    api_v2_vuser_coupon_code_create: 'api_v2/vuser/coupon-code/create',
    api_v2_order_count: 'api_v2/order/count',
    api_v2_vuser_info: 'api_v2/vuser/info',
    api_v2_vuser_account_detail: 'api_v2/vuser-account-detail/list',
    '': ''
}
