import Cookies from 'js-cookie'

const base = (() => {
    return [
        process.env.VUE_APP_HTTPS === 'true' ? 'https' : 'http',
        '://',
        process.env.VUE_APP_SERVER,
        process.env.port ? ':' + process.env.port : '',
        process.env.VUE_APP_CONTEXT_PATH
    ].join('')
})()

export default {
    url: (source = '') => {
        source = '/' + source.replace(/^\/+/i, '')
        return base + source
    },
    getClientId: function () {
        let clientId = Cookies.get('client_id') || ''

        if (!clientId) {
            clientId = localStorage.clientId || ''
        }

        if (!clientId) {
            clientId = Date.now() + '' + Math.floor(Math.random() * 100000000)
        }

        Cookies.set('client_id', clientId, { expires: 365, domain: '.all-yoga.com.cn' })
        localStorage.setItem('client_id', clientId)
        return clientId
    },
    source: (url = '') => url.replace(base, ''),
    accept: (format = process.env.VUE_APP_FORMAT) => 'application/x.api-' + process.env.VUE_APP_PLATFORM + '.' + process.env.VUE_APP_VERSION + '+' + format
}
