const preventToucheMove = function (e) {
    e.preventDefault()
}

let stopped = 0

export default {
    noop: () => {
    },
    randomString (t) {
        return '.' + t.replace(/[xy]/g, function (c) {
            const r = Math.random() * 16 | 0
            const v = c === 'x' ? r : (r & 0x3 | 0x8)
            return v.toString(16)
        })
    },
    getScrollOffset () {
        const supportPageOffset = window.pageXOffset !== undefined
        const isCSS1Compat = ((document.compatMode || '') === 'CSS1Compat')

        const x = supportPageOffset ? window.pageXOffset : isCSS1Compat ? document.documentElement.scrollLeft : document.body.scrollLeft
        const y = supportPageOffset ? window.pageYOffset : isCSS1Compat ? document.documentElement.scrollTop : document.body.scrollTop
        return { x, y }
    },
    getPageOffsetY ($el) {
        const rect = $el.getBoundingClientRect()
        const scrollOffset = this.getScrollOffset()
        return rect.top + scrollOffset.y
    },
    formatVotes (votes) {
        if (votes) {
            if (votes > 1000) {
                votes = (votes / 1000).toFixed(1) + 'K'
            }
            votes = '(' + votes + ')'
        } else {
            votes = ''
        }
        return votes
    },

    stopDefaultTouch: function () {
        if (stopped === 0) {
            document.addEventListener('touchmove', preventToucheMove, { passive: false, capture: false })
        }
        stopped += 1
    },
    resetDefaultTouch: function () {
        stopped -= 1

        if (stopped <= 0) {
            stopped = 0
            document.removeEventListener('touchmove', preventToucheMove)
        }
    },
    throttle: function (func, wait, options) {
        /* options的默认值
         *  表示首次调用返回值方法时，会马上调用func；否则仅会记录当前时刻，当第二次调用的时间间隔超过wait时，才调用func。
         *  options.leading = true;
         * 表示当调用方法时，未到达wait指定的时间间隔，则启动计时器延迟调用func函数，若后续在既未达到wait指定的时间间隔和func函数又未被调用的情况下调用返回值方法，则被调用请求将被丢弃。
         *  options.trailing = true;
         * 注意：当options.trailing = false时，效果与上面的简单实现效果相同
         */
        let context, args, result
        let timeout = null
        let previous = 0
        if (!options) options = {}
        const later = function () {
            previous = options.leading === false ? 0 : Date.now()
            timeout = null
            result = func.apply(context, args)
            if (!timeout) context = args = null
        }
        return function () {
            const now = Date.now()
            if (!previous && options.leading === false) previous = now
            // 计算剩余时间
            const remaining = wait - (now - previous)
            context = this
            args = arguments
            // 当到达wait指定的时间间隔，则调用func函数
            // 精彩之处：按理来说remaining <= 0已经足够证明已经到达wait的时间间隔，但这里还考虑到假如客户端修改了系统时间则马上执行func函数。
            if (remaining <= 0 || remaining > wait) {
                // 由于setTimeout存在最小时间精度问题，因此会存在到达wait的时间间隔，但之前设置的setTimeout操作还没被执行，因此为保险起见，这里先清理setTimeout操作
                if (timeout) {
                    clearTimeout(timeout)
                    timeout = null
                }
                previous = now
                result = func.apply(context, args)
                if (!timeout) context = args = null
            } else if (!timeout && options.trailing !== false) {
                // options.trailing=true时，延时执行func函数
                timeout = setTimeout(later, remaining)
            }
            return result
        }
    }
}
