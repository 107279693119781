<template>
    <nav class="footer-bar">
        <ul>
            <li :class="{active: current === 'index'}">
                <router-link to="/">
                    <img v-if="current === 'index'"
                         :src="uiConfig.bottomBar.nav_index_active" alt="">
                    <img v-else :src="uiConfig.bottomBar.nav_index" alt="">
                    <div class="footer-bar-item-ft" :style="{color: current === 'index'  ?
                     uiConfig.brandColor : ''}">首页</div>
                </router-link>
            </li>
            <li :class="{active: current === 'series'}">
                <router-link to="/series/list">
                    <img v-if="current === 'series'"
                         :src="uiConfig.bottomBar.nav_series_active" alt="">
                    <img v-else :src="uiConfig.bottomBar.nav_series" alt="">
                    <div class="footer-bar-item-ft" :style="{color: current === 'series'  ?
                     uiConfig.brandColor : ''}">系列课</div>
                </router-link>
            </li>
            <li :class="{active: current === 'feed'}" class="nav_feed">
                <router-link to="/feed/index">
                    <img v-if="current === 'feed'"
                         :src="uiConfig.bottomBar.nav_moments_active" alt="">
                    <img v-else :src="uiConfig.bottomBar.nav_moments" alt="">
                    <div class="footer-bar-item-ft" :style="{color: current === 'feed'  ?
                     uiConfig.brandColor : ''}">时刻</div>
                </router-link>
            </li>
            <li :class="{active: current === 'my'}">
                <router-link to="/my/index">
                    <transition name="msg_trans" appear>
                        <span @click.stop.prevent="enterNotice"
                              v-if="msgCount" class="msg_pop">{{msgCount}}条新消息</span>
                    </transition>
                    <img v-if="current === 'my'"
                         :src="uiConfig.bottomBar.nav_my_active" alt="">
                    <img v-else :src="uiConfig.bottomBar.nav_my" alt="">
                    <div class="footer-bar-item-ft" :style="{color: current === 'my' ?
                     uiConfig.brandColor : ''}">我的</div>
                </router-link>
            </li>
        </ul>
    </nav>
</template>

<script>
    export default {
        name: 'FooterBar',
        props: ['current'],
        data () {
            return {
                msgCount: 0,
                uiConfig: this.$app.siteState.uiConfig
            }
        },
        mounted () {
            this.$app.msgState.then(c => {
                this.msgCount = c
            })

            this.$eventBus.$on('msgStateChange', c => {
                this.msgCount = c
            })
        },
        methods: {
            enterNotice () {
                this.$router.push({
                    name: 'user_notice'
                })
            }
        }
    }
</script>

<style scoped lang="less">
    @import "../less/_variables";
    @import "../less/_mixin";
    @import "../less/sprite/public1";

    .footer-bar {
        @color: @text-color;
        @active-color: @colorPrimary;
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        z-index: @base-zindex + 1;
        background-color: @colorGray1;
        height: @bottom-bar-height;

        &:before {
            .setTopLine(@colorGray3);
        }

        ul {
            margin: 0;
            padding: 0;
            display: flex;
            align-items: center;
        }

        li {
            flex: 1;

            a {
                position: relative;
            }

            &.has_red_dot {
                a:after {
                    content: "";
                    height: 6px;
                    width: 6px;
                    border-radius: 100%;
                    background-color: @active-color;
                    position: absolute;
                    top: 6px;
                    right: 18px;
                }

                &.active {
                    a:after {
                        display: none;
                    }
                }
            }

        }

        li, a {
            display: block;
        }

        a {
            text-align: center;
            color: @color;

            padding-top: 6px;
            line-height: 18px;
            font-size: 12px;

            position: relative;

            img {
                width: 24px;
            }
        }

        .msg_pop {
            font-size: 12px;
            line-height: 30px;
            padding: 0 10px;
            background-color: @colorPrimary;
            color: @white;
            border-radius: 30px;
            position: absolute;
            bottom: 100%;
            left: 50%;
            white-space: nowrap;
            transform: translate(-50%, 0);

            &:after {
                content: '';
                .setArrow(bottom, 4px, @colorPrimary);
                position: absolute;
                top: 100%;
                left: 50%;
                transform: translateX(-50%);
            }
        }

        a:active {
            img {
                animation: scale .3s;
            }
        }

        .active a {
            color: @active-color;
        }

        .footer-bar-item-ft {
            transform: scale(0.8) translateY(-1px);
        }
    }

    @keyframes scale {
        from {
            transform: scale(.5);
        }

        to {
            transform: scale(1);
        }
    }

    .msg_pop {
        &.msg_trans-enter-active {
            transition: all .3s;
            transform-origin: center center;
        }

        &.msg_trans-enter {
            transform: translate(-50%, 50%);
            opacity: 0;
        }
    }
</style>
