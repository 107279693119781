import axios from 'axios'
import Api from '@/utils/apiForNative'

function makeAxios (method, url = '', data = {}, config = {}) {
    data._rnd = Math.random()
    if (method === 'get') {
        config.params = data
    }

    let p

    if (method === 'get') {
        p = axios[method](Api.url(url), config)
    } else {
        p = axios[method](Api.url(url), data, config)
    }

    return p.then(res => {
        return Promise.resolve(res.data)
    })
}

const ajax = {
    get: function (url, data, config) {
        return makeAxios('get', url, data, config)
    },
    post: function (url, data, config) {
        return makeAxios('post', url, data, config)
    }
}

export default ajax
