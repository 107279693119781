<template>
    <transition name="fade" :duration="100"
                @after-enter="afterEnter"
                @before-enter="beforeEnter">
        <div class="popup_layer" :class="{'fullscreen' : fullScreen}" v-if="showMask"
             :style="{'z-index': selfZIndex}">
            <div v-if="fullScreen" class="mask" @click.stop="!modal && dismiss()" :style="{opacity: maskOpacity}"></div>
            <transition :duration="240" :name="'trans_' + position"
                        @after-leave="afterLeave">
                <div class="content" v-if="showContent" :class="position"
                     :style="{width: contentWidth}">
                    <slot></slot>
                    <a href="#" @click.prevent.stop="dismiss" v-if="closeBtn" class="btn_close"></a>
                </div>
            </transition>
        </div>
    </transition>
</template>

<script>
    const baseZIndex = 100
    let topLayerZIndex = baseZIndex

    export default {
        name: 'PopupComponent',
        props: {
            visible: Boolean,
            closeBtn: {
                type: Boolean,
                default: true
            },
            fullScreen: {
                type: Boolean,
                default: true
            },
            disableScroll: {
                type: Boolean,
                default: true
            },
            maskOpacity: {
                type: Number,
                default: 0.5
            },
            zIndex: {
                type: Number,
                default: baseZIndex
            },
            fixedZIndex: {
                type: Boolean,
                default: false
            },
            position: {
                type: String,
                default: 'center'
            },
            contentWidth: {
                type: String,
                default: ''
            },
            modal: {
                type: Boolean,
                default: false
            }
        },
        model: {
            event: 'input',
            prop: 'visible'
        },
        data () {
            return {
                showMask: this.model,
                showContent: false,
                selfZIndex: this.zIndex
            }
        },
        watch: {
            visible (newVal) {
                /* if (newVal) {
                        this.showMask = true;
                    } else {
                        this.showContent = false;
                    } */
                // 如果newVal为true，则this.showMask会触发transition
                // 如果接下来用户操作很快，在showMask的transtion结束前，再次触发visible改变
                // 导致newVal为false，则this.showContent会被设置为false
                // 而当this.mask触发的transition结束后，又会将this.showContent设置为true，导致visible showMask showContent的状态不一致
                // 改进如下：

                if (newVal) {
                    if (this.showMask) {
                        this.showContent = true
                    }
                    this.showMask = true

                    if (!this.fixedZIndex) {
                        this.selfZIndex = ++topLayerZIndex
                    }
                    this.lockScroll()
                } else {
                    if (!this.showContent) {
                        this.showMask = false
                    }
                    this.showContent = false

                    if (!this.fixedZIndex) {
                        this.selfZIndex = --topLayerZIndex
                    }
                    this.resetScroll()
                }
            }
        },
        beforeDestroy () {
            this.resetScroll()
        },
        methods: {
            lockScroll () {
                this.disableScroll && setTimeout(() => {
                    if (!this.bodyOverflow) {
                        this.bodyOverflow = document.body.style.overflow
                    }
                    document.body.style.overflow = 'hidden'
                }, 100)
            },
            resetScroll () {
                this.disableScroll && setTimeout(() => {
                    if (this.bodyOverflow !== 'hidden') {
                        document.body.style.overflow = this.bodyOverflow
                    }
                    this.bodyOverflow = null
                }, 100)
            },
            dismiss () {
                this.$emit('input', false)
            },
            beforeEnter () {
                // this.initAt = Date.now()
            },
            afterEnter () {
                // console.log('popup enter', Date.now() - this.initAt)
                if (this.visible) {
                    this.showContent = true
                }
            },
            afterLeave () {
                if (!this.visible) {
                    this.showMask = false
                }
            }
        }
    }
</script>

<style scoped lang="less">
    @import "../less/_variables";
    @import "../less/_mixin";

    @mask_transition_duration: .1s;
    @content_transition_duration: .2s;

    .fade-enter-active, .fade-leave-active {
        transition: opacity @mask_transition_duration;
    }

    .fade-enter, .fade-leave-to {
        opacity: 0;
    }

    .trans_center-enter-active, .trans_center-leave-active {
        transition: opacity @content_transition_duration;
    }

    .trans_center-enter, .trans_center-leave-to {
        opacity: 0;
    }

    .trans_bottom-enter-active, .trans_bottom-leave-active {
        transition: opacity @content_transition_duration, transform @content_transition_duration;
    }

    .trans_bottom-enter, .trans_bottom-leave-to {
        opacity: 0;
        transform: translateX(-50%) translateY(50%) !important;
    }

    .trans_top-enter-active, .trans_top-leave-active {
        transition: opacity @content_transition_duration, transform @content_transition_duration;
    }

    .trans_top-enter, .trans_top-leave-to {
        opacity: 0;
        transform: translateX(-50%) translateY(-50%) !important;
    }

    .content {
        .fullscreen & {
            position: absolute;

            &.center {
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }

            &.top {
                left: 50%;
                top: 0;
                transform: translateX(-50%);
            }

            &.bottom {
                left: 50%;
                bottom: 0;
                transform: translateX(-50%);
            }
        }
    }

    .popup_layer {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        .no_select();

        &.fullscreen {
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            transform: none;
        }
    }

    .mask {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #000;
    }

    .btn_close {
        position: absolute;
        width: 40px;
        height: 40px;
        border-radius: 100%;
        background-color: @white;
        bottom: -60px;
        left: 50%;
        margin-left: -20px;

        &:before, &:after {
            content: '';
            position: absolute;
            width: 24px;
            height: 1px;
            top: 50%;
            left: 50%;
            margin-left: -12px;
            background-color: @colorGray;
        }

        &:before {
            transform: rotate(45deg);
        }

        &:after {
            transform: rotate(-45deg);
        }
    }
</style>
