import PopupComponent from './PopupComponent'

export default {
    components: { PopupComponent },
    model: {
        event: 'input',
        prop: 'visible'
    },
    props: {
        visible: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            popupVisible: this.visible
        }
    },
    watch: {
        visible (v) {
            this.popupVisible = v
        }
    },
    methods: {
        dismiss () {
            this.$emit('input', false)
        },
        syncPopupModelProp ($event) {
            this.$emit('input', $event)
        }
    }
}
